<template>
	<div>

		<div>
			<div class=" w-100 d-flex justify-content-center">
				<img class="w-100 h-100" style="object-fit: cover;" :src="$imgURL + joinus_banner.data" alt=""
					v-if="joinus_banner" />
			</div>
			<div class="d-flex  justify-content-center" ref='chengyao'>

				<div class="d-flex bg-white   flex-column flex-sm-row justify-content-center align-items-center   container py-1 py-sm-3 "
					v-if="joinus_introduce">
					<div class="text-yellow1 d-block d-sm-none text-center  font-md py-1 animate__animated animate__fadeInRight py-sm-2 "
						style="font-size: 0.45rem;opacity: 1;  transition-delay: 0s;transform: translateY(0);">长脸叔叔诚邀加盟
					</div>
					<div class="text-center    p-0  col-sm-6 animate__animated animate__fadeInLeft">
						<img :src="$imgURL + joinus_introduce.data" class="col-6 col-sm-12" alt="" />
					</div>
					<div class="  px-1  col-sm-6 p-0 pl-0-5  pl-sm-3 d-flex flex-column justify-content-between ">
						<div class="text-yellow1 text-center d-none d-sm-block font-md py-1 animate__animated animate__fadeInRight py-sm-2 "
							style="font-size: 0.45rem;opacity: 1;  transition-delay: 0s;transform: translateY(0);">
							长脸叔叔诚邀加盟</div>
						<div class="text-yellow1 font-smd d-flex flex-column mt-1 mt-sm-0 ">
							<div class="d-flex flex-row line-3" v-for="(v, i) in joinus_introduce_list" :key="i">
								<div
									class="col-5 col-sm-4 p-0 text-sm-left text-right pr-1 pr-sm-0 font  animate__animated animate__fadeInRight">
									0{{ i + 1 }} | {{v[0]}}</div>
								<div class="col-6 p-0 text-left font  text-dark animate__animated animate__fadeInRight">
									{{ v[1] }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="w-100 bgg text-center  text-white h-100"
				:style="{background:' url('+$imgURL+joinus_process_background.data+')   no-repeat '}"
				style="background-size:100% 100% !important;" id='liucheng' ref='liucheng'>
				<div class="bgg-mask py-2 py-sm-4">
					<div class="font-weight-bold font-lg animate__animated animate__fadeInDown" v-show="liuchengShow">
						FRANCHISE
						PROCESS</div>
					<div class="font-md my-1 animate__animated animate__fadeInDown" v-show="liuchengShow">加盟流程</div>
					<div class="d-flex  px-1 flex-row justify-content-center text-white py-1 py-sm-2 font-sm">
						<div v-show="liuchengShow" style="width: 2rem"
							class="bg-btn1 font-smd rounded-lg p-btn pointer animate__animated animate__fadeInLeft"
							@click="toScroll1">
							品牌加盟申请
						</div>
						<div style="width: 2rem" v-show="liuchengShow"
							class="bg-yellow font-smd ml-2 p-btn rounded-lg pointer animate__animated animate__fadeInRight"
							@click="toScroll">
							联系我们
						</div>
					</div>
					<div class="d-flex px-1 px-sm-0  align-items-center justify-content-center" ref='liucheng1'>
						<div class="d-flex  flex-row flex-wrap   container">
							<div v-show="liucheng1Show" v-for="(item, index) in joinus_process_list"
								class="mt-0   mt-sm-2  animate__animated animate__fadeInUp w-20">

								<div class="d-flex justify-content-center px-0-2  px-lg-1 py-0-5 py-sm-0">
									<img src="../assets/img/l-img/join-bg-item.png" class="wh-1-5" alt="" />
								</div>
								<div class="position-relative">
									<div class="position-absolute   w-100 top-1-5">
										<div
											class="d-flex align-items-center justify-content-center font-lmd font-weight-bold wh-1-5 ">
											<div class="text-white">{{item[0]}}</div>
											<!-- <div class="text-white"><span v-if="index<9">0</span>{{index+1}}</div> -->
										</div>
									</div>
									<div class="text-white text-left  font px-0-2  px-lg-1  mt-0 mt-sm-1">
										{{item[1]}}
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 活动支持 -->
			<activity></activity>

			<!-- 运营支持 -->
			<operation></operation>

			<!-- 盟校展示 -->
			<Campus></Campus>

			<!-- 加盟日记 -->
			<Diary></Diary>

		</div>
		<!-- cao:10/8添加 活动支持 -->
		<div class="w-100 text-center text-white bg-yellow h-100">

		</div>

		<div class="py-sm-4 py-1 d-flex flex-column align-items-center background" ref="wenchuang">
			<div>
				<div class=" font-weight-bold font-lg text-center animate__animated animate__fadeInDown "
					v-show="wenchuangShow">CULTURAL
					AND CREATIVE SUPPORT</div>
				<div class="font-md   mb-0 mb-sm-2 mt-1 text-center animate__animated animate__fadeInDown"
					v-show="wenchuangShow">文创支持</div>
			</div>

			<div class="container px-0 ">
				<el-carousel :interval="5000" :height="swiperHeight" arrow="always">
					<el-carousel-item v-for="item in cultural_creation_support.data" :key="item">
						<div class="d-flex justify-content-center  h-100 w-100">
							<div
								class="container px-0-5 px-sm-1 px-lg-3  w-100 h-100  d-flex align-items-center justify-content-center">
								<img class="w-100" :src="$imgURL+item" alt="">
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>


		</div>


		<div class="w-100 bgg text-center  text-white h-100"
			:style="{background:' url('+$imgURL+joinus_support_background.data+')   no-repeat '}"
			style="background-size:100% 100% !important;" ref="jiameng">
			<div class="bgg-mask">
				<div class="d-flex flex-column justify-content-center align-items-center   py-2 py-sm-4 " id="zhichi">
					<div>
						<div class=" font-lg text-center font-weight-bold    text-white  animate__animated animate__fadeInUp"
							v-show="jiamengShow">ALLIANCE
							SUPPORT</div>
						<div class="font-md text-center mb-2 mt-1 text-white animate__animated animate__fadeInUp"
							v-show="jiamengShow">加盟支持</div>
					</div>

					<div class="d-flex flex-column justify-content-center px-0-5 px-sm-0  container" ref="jiameng1">
						<div class="mt-0 mt-sm-2 d-flex flex-row flex-wrap font-smd " v-show="jiameng1Show">
							<div class="col-4 col-lg-2 mb-1 mb-md-1 text-center animate__animated animate__fadeInUp"
								@click="changeZhichiIndex(index)" v-for="(item, index) in zhichiList" :key="index">
								<div :style="{ backgroundColor: zhichiColor[index] }" style="padding: 0.08rem 0;
				  box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.4);"
									class="pointer text-white rounded-lg  w-100 font-smd  font-weight-bold ">
									{{ item.name }}
								</div>
								<div class="w-100 d-flex justify-content-center ">
									<div class="demo" v-if="zhichiIndex == index"
										:style="{ borderTopColor: zhichiColor[index] }"></div>
								</div>

							</div>
						</div>
						<div class="d-flex font-smd flex-column " ref="jiameng2">
							<div v-show="jiameng2Show" v-for="(item, index) in zhichiList[zhichiIndex].list"
								:key="index">
								<div style="margin-top: 4px;"
									class="bg-yellow   p-0-5  text-white shadow d-flex flex-row align-items-center animate__animated animate__fadeInUp">
									<div class="col-1 font-md p-0 animate__animated animate__fadeInUp"
										v-show="jiameng2Show" style="color: rgba(255,255,255,0.7);">0{{ index + 1 }}
									</div>
									<div class="font text-left animate__animated animate__fadeInUp"
										v-show="jiameng2Show">{{ item }}</div>
								</div>

							</div>
						</div>
					</div>

				</div>
			</div>
		</div>





		<div class="py-2 py-sm-4  bg-white d-flex flex-column justify-content-center align-items-center" ref="jiaoxue">
			<div id="xiaoqu">
				<div class=" font-lg font-weight-bold text-center animate__animated animate__fadeInDown"
					v-show="jiaoxueShow">COMFORTABLE
					TEACHING ENVIRONMENT</div>
				<div class="font-md text-center  mt-1 mb-0 mb-sm-2   animate__animated animate__fadeInDown"
					v-show="jiaoxueShow">教学环境</div>
			</div>


			<div class="mt-0 mt-sm-2  container">
				<el-carousel indicator-position="none" :height="swiperHeight" :interval="5000" arrow="nerver">
					<el-carousel-item v-for="(item, index) in teaching_environment.data" :key="index">
						<div class="w-100 h-100 d-flex align-items-center justify-content-center">
							<img :src="$imgURL + item" style=" object-fit: cover;" class="w-100" alt="" />
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>

			<div class="mt-2 mt-sm-4 container" ref="hehuo" id='hehuo'>
				<div class=" font-lg font-weight-bold text-center   animate__animated animate__fadeInDown"
					v-show="hehuoShow">SINCERELY
					INVITE NATIONAL COOPERATORS </div>
				<div class="font-md text-center mt-2 font-weight-bold animate__animated animate__fadeInDown"
					v-show="hehuoShow">长脸叔叔艺术绘馆
					诚邀全国事业合伙人</div>
				<div class="mt-2 mt-sm-4 d-flex    flex-column flex-lg-row  align-items-center " ref="hehuo1">
					<div
						class="col-12 col-lg-6   d-flex flex-row flex-wrap font-smd align-items-center justify-content-end ">
						<div class="   col-6 p-0 p-0-5 animate__animated animate__fadeInLeft" v-show="hehuo1Show"
							v-for="(item, index) in companyList" :key="index">
							<div class="position-relative" v-if="index <= 1">
								<div
									class="position-absolute  align-items-center justify-content-center  w-100 d-flex top-0-4">
									<strong></strong>
									<img :src="$imgURL + item.icon" style="border-radius: 100%;" class=" h-0-8"
										alt="" />

								</div>
							</div>
							<div
								class="bg-zhichi py-sm-2 py-0-5   h-100 d-flex flex-column align-items-center justify-content-center">
								<div class="font-md mt-1">{{ item.name }}</div>
								<div class="font my-0-5">{{ item.brief_en }}</div>
								<div class="font my-1 text-center   ">{{ item.brief_cn }}</div>
							</div>
							<div class="position-relative" v-if="index > 1">
								<div
									class="position-absolute  align-items-center justify-content-center  w-100 d-flex top-0-4">

									<img :src="$imgURL + item.icon" style="border-radius: 100%;" class=" h-0-8"
										alt="" />

								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-6 mt-2 mt-lg-0  ">
						<el-form ref="form" :model="form" :rules="indexRules" label-width="80px" label-position="top"
							class="animate__animated animate__fadeInRight" v-show="hehuo1Show">
							<el-form-item style="margin-left: 0rem !important" label="" prop="name">
								<el-input v-model="form.name" placeholder="姓名:"></el-input>
							</el-form-item>
							<el-form-item style="margin-left: 0rem !important" label="" prop="sex">
								<el-input v-model="form.sex" placeholder="性别:"></el-input>
							</el-form-item>
							<el-form-item style="margin-left: 0rem !important" label="" prop="phone">
								<el-input v-model="form.phone" placeholder="联系电话:"></el-input>
							</el-form-item>
							<el-form-item style="margin-left: 0rem !important" label="" prop="city">
								<el-input v-model="form.city" placeholder="申请城市:"></el-input>
							</el-form-item>
							<el-form-item style="margin-left: 0rem !important" label="" prop="ability">
								<el-input v-model="form.ability" placeholder="投资能力:"></el-input>
							</el-form-item>
							<el-form-item style="margin-left: 0rem !important" label="" prop="email">
								<el-input v-model="form.email" placeholder="邮箱:"></el-input>
							</el-form-item>
							<el-form-item style="margin-left: 0rem !important" label="" prop="address">
								<el-input v-model="form.address" placeholder="通讯地址:"></el-input>
							</el-form-item>
							<el-form-item label="" prop="message">
								<el-input type="textarea" placeholder="留言:" v-model="form.message"></el-input>
							</el-form-item>

							<el-form-item class="text-right">
								<el-button type="primary" @click="onSubmit">提交申请</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>


	</div>


	</div>
</template>

<script>
import activity from './activity.vue'
import operation from './operation.vue'
import campus from './campus.vue'
import diary from './diary.vue'
import Campus from './campus.vue';
import Diary from './diary.vue';

export default {
	inject: ['scroll'],
	components: {
		activity,
		operation,
		Campus,
		Diary
	},
	data() {
		const name = (rule, value, callback) => {
			if (!value) {
				callback(new Error("请输入姓名"));
			} else {
				callback();
			}
		};
		const sex = (rule, value, callback) => {
			if (!value) {
				callback(new Error("请输入性别"));
			} else {
				callback();
			}
		};
		const phone = (rule, value, callback) => {
			if (value) {
				if (this.phoneReg(value)) {
					callback(new Error("手机号码有误，请重新填写"));
				} else {
					callback();
				}
			} else {
				callback(new Error("请输入手机号"));
			}
		};
		const city = (rule, value, callback) => {
			if (!value) {
				callback(new Error("请输入申请城市"));
			} else {
				callback();
			}
		};
		const ability = (rule, value, callback) => {
			if (!value) {
				callback(new Error("请输入投资能力"));
			} else {
				callback();
			}
		};
		const email = (rule, value, callback) => {
			if (!value) {
				callback(new Error("请输入邮箱"));
			} else {
				callback();
			}
		};
		const address = (rule, value, callback) => {
			if (!value) {
				callback(new Error("请输入地址"));
			} else {
				callback();
			}
		};
		const message = (rule, value, callback) => {
			if (!value) {
				callback(new Error("请输入留言"));
			} else {
				callback();
			}
		};

		return {

			chengyaoShow: false,
			liuchengShow: false,
			liucheng1Show: false,
			wenchuangShow: false,
			wenchuan1gShow: false,
			jiamengShow: false,
			jiameng1Show: false,
			jiameng2Show: false,
			jiaoxueShow: false,
			hehuoShow: false,
			hehuo1Show: false,




			isLoad: false,
			indexRules: {
				name: [{
					required: true,
					trigger: "blur",
					validator: name
				}],
				sex: [{
					required: true,
					trigger: "blur",
					validator: sex
				}],
				phone: [{
					required: true,
					trigger: "blur",
					validator: phone,
				},],
				city: [{
					required: true,
					trigger: "blur",
					validator: city
				}],
				ability: [{
					required: true,
					trigger: "blur",
					validator: ability
				}],
				email: [{
					required: true,
					trigger: "blur",
					validator: email
				}],
				address: [{
					required: true,
					trigger: "blur",
					validator: address,
				},],
				message: [{
					required: true,
					trigger: "blur",
					validator: message
				}],
			},

			form: {
				name: "",
				sex: "",
				phone: "",
				city: "",
				ability: "",
				email: "",
				address: "",
				message: "",
			},
			zhichiColor: [
				"#FEA117",
				"#8DDB18",
				"#0FE2D2",
				"#299FFE",
				"#A25AD4",
				"#EE3896",
			],
			zhichiIndex: 0,
			zhichiList: [],

			companyList: [], //合作伙伴

			joinus_banner: null,

			joinus_introduce: null,
			joinus_process_background: null,
			joinus_support_background: null,
			joinus_introduce_list: [],

			joinus_process_list: [],
			cultural_creation_support: [],

			teaching_environment: [],

			banner_curriculum: [],
			banner_news: [],
			banner_about: [],

			requireList: [

				"joinus_banner",
				"joinus_introduce",
				"joinus_introduce_list",
				"joinus_process_background",
				// "joinus_introduce_list",
				"joinus_process_list",
				"joinus_support_background",
				"cultural_creation_support",
				"teaching_environment",
				"banner_curriculum",
				"banner_news",
				"banner_about",
			],



			swiperHeight: '5rem',
		};
	},
	created() {
		this.getCompany();
		this.getsupport(); //加盟支持
		this.getConfig()

	},

	mounted() {
		this.swiperHeight = document.body.clientWidth < 567 ? '3rem' : '5rem'
		let type = this.$route.query.type
		this.scrollView(type)
		let that = this
		window.addEventListener('scroll', this.handleScroll)

		window.onresize = () => {
			return (() => {
				setTimeout(function () {
					that.swiperHeight = document.body.clientWidth < 567 ? '3rem' : '5rem'
				}, 1000)

			})()
		}


	},
	methods: {

		handleScroll() { // 实现当滚动到指定位置，触发动画

			let _this = this
			let refArray = [{
				ref: 'chengyao',
				show: 'chengyaoShow'
			}, {
				ref: 'liucheng',
				show: 'liuchengShow'
			}, {
				ref: 'liucheng1',
				show: 'liucheng1Show'
			}, {
				ref: 'wenchuang',
				show: 'wenchuangShow'
			}, {
				ref: 'wenchuang1',
				show: 'wenchuang1Show'
			},
			{
				ref: 'jiameng',
				show: 'jiamengShow'
			},
			{
				ref: 'jiameng1',
				show: 'jiameng1Show'
			}, {
				ref: 'jiameng2',
				show: 'jiameng2Show'
			}, {
				ref: 'jiaoxue',
				show: 'jiaoxueShow'
			},
			{
				ref: 'hehuo',
				show: 'hehuoShow'
			}, {
				ref: 'hehuo1',
				show: 'hehuo1Show'
			}
			]
			refArray.forEach((r, i) => {
				_this.gdjz(r.ref, 200, () => {
					_this[r.show] = true
				})
			})


		},
		gdjz(div, offset, callback) {
			let dom = this.$refs[div] // 等同于document.querySelector('.earth') true

			if (dom) {
				var a, b, c, d;
				d = dom.offsetTop // 元素距离相对父级的高度，这里父级指的是body
				a = eval(d + offset)
				b = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop //  获取窗口滚动条高度
				c = document.documentElement.clientHeight || document.body.clientHeight // 获取浏览器可视区的高度



				if (b + c > a) {
					callback && callback()
				}
			}
		},













		toScroll() {
			this.scroll()
		},
		toScroll1() {
			this.scrollView(3)
		},
		getConfig() {

			this.requireList.forEach((v) => {
				console.log(v);
				this.onConfig(v);
			});
		},
		scrollView(type) {
			if (type == 0) {
				setTimeout(() => {
					document.getElementById('liucheng').scrollIntoView()
				}, 500)
			} else if (type == 1) {
				setTimeout(() => {
					document.getElementById('zhichi').scrollIntoView()
				}, 800)
			} else if (type == 2) {
				setTimeout(() => {
					document.getElementById('xiaoqu').scrollIntoView()
				}, 800)
			} else if (type == 3) {
				setTimeout(() => {
					document.getElementById('hehuo').scrollIntoView()
				}, 800)
			}



		},
		scroll() {
			document.getElementById('footer').scrollIntoView()
		},


		phoneReg(phone) {
			if (!/^1[3456789]\d{9}$/.test(phone)) {
				console.log(111);
				return "手机号码有误，请重新填写";
			} else {
				console.log(222);

				return "";
			}
		},

		changeZhichiIndex(index) {
			this.zhichiIndex = index;
		},

		// /api/join_us/support

		getsupport() {
			var params = new URLSearchParams();
			//   params.append("type", this.navIndex + 1);
			//   params.append("page", "1");
			this.$axios({
				method: "post",
				url: this.$apiURL + "/api/join_us/support",
				data: params,
			}).then((res) => {
				if (res.code < 1) {
					console.log("网络错误");
					return false;
				}
				this.zhichiList = res.data.data;

				// console.log(this.companyList);
			});
		},

		getCompany() {
			var params = new URLSearchParams();
			//   params.append("type", this.navIndex + 1);
			//   params.append("page", "1");
			this.$axios({
				method: "post",
				url: this.$apiURL + "/api/join_us/company",
				data: params,
			}).then((res) => {
				if (res.code < 1) {
					console.log("网络错误");
					return false;
				}
				this.companyList = res.data.data;

				// console.log(this.companyList);
			});
		},

		// 申请加入

		onSubmit() {
			var that = this;
			that.$refs.form.validate(async (valid) => {
				if (valid) {
					that.api.joinus(that.form).then((res) => {
						if (res.data.code == 1) {
							alert(res.data.msg);

							that.form = {};
						}
					});
				}
			});
		},

		onConfig(key) {
			// console.log(key);
			var that = this;
			that.api.config(key).then((res) => {
				if (res.data.code == 1) {
					// console.log(res.data);
					if (key == 'joinus_introduce_list') {
						this[key] = Object.entries(res.data.data)
					} else if (key == 'joinus_process_list') {
						this[key] = Object.entries(res.data.data).sort()

					} else {
						this[key] = res.data;
					}



					if (key == 'banner_about') {
						this.isLoad = true
					}

				}
			});
		},
	},
};
</script>

<style scoped>
.text-yellow1 {
	color: rgba(254, 195, 57) !important;
}

.w-20 {
	width: 20%;
}

.line-3 {
	line-height: 3;
}

.h-0-8 {
	height: 0.8rem;
}

.top-0-4 {
	top: -0.4rem;
}

.top-1-5 {
	top: -1.7rem
}

.el-button--primary {
	color: #fff !important;
	background-color: #9FD9F6 !important;
	border-color: #9FD9F6 !important;
}

.hehuo-img {
	width: 5rem;
	object-fit: contain;
}

@media (max-width: 920px) {
	.px-0-2 {
		padding: 0 0.1rem;
	}
}

@media (max-width: 576px) {
	.w-20 {
		width: 25%;
	}

	.line-3 {
		line-height: 1.8;
	}

	.px-0-2 {
		padding: 0 0.05rem;
	}

	.h-0-8 {
		height: 0.5rem;
	}

	.top-0-4 {
		top: -0.25rem;
	}

	.top-1-5 {
		top: -0.9rem !important
	}

	.wh-1-5 {
		height: 0.8rem !important;
	}

	.hehuo-img {
		width: 3rem !important
	}

	.el-carousel__container {
		position: relative;
		height: 2rem !important;
	}

	.el-carousel__arrow {
		height: 0.4rem !important;
		width: 0.4rem !important;
		font-size: 0.2rem !important;
	}
}

.el-carousel__container {
	position: relative;
	height: 5rem !important;
}

/* .wh-15{
		width: 1500px;
	} */
.background {
	/* width: 100%;
		height: 100vh;
		z-index: -1;
		position: absolute;
		overflow: hidden; */
	background-image: url("../assets/img/bg.png");

	background-size: 100%;
	/* height: 100%;
		position: fixed;
		width: 100% */
}


.bgg {
	position: relative;
	background-size: cover !important;

	height: 100%
}


.bgg-mask {
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, .4);
}

.el-input {
	font-size: 0.14rem;
}

.el-form-item__content {
	line-height: 0.4rem;
	position: relative;
}

.el-input__inner {
	-webkit-appearance: none;
	background-color: #fff;
	background-image: none;
	border-radius: 0.04rem;
	border: 1px solid #dcdfe6;
	box-sizing: border-box;
	color: #606266;
	display: inline-block;
	font-size: inherit;
	height: 0.4rem;
	line-height: 0.4rem;
	outline: 0;
	padding: 0 0.15rem;
	transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	width: 100%;
}

.el-carousel__arrow {
	height: 1rem;
	width: 1rem;
	font-size: 0.5rem;
	background-color: transparent;
	/* i { */
	color: #606266;
	/* } */
}

.el-button {
	display: inline-block;
	line-height: 1;
	white-space: nowrap;
	cursor: pointer;
	background: #fff;
	border: 1px solid #dcdfe6;
	color: #606266;
	-webkit-appearance: none;
	text-align: center;
	box-sizing: border-box;
	outline: 0;
	margin: 0;
	transition: 0.1s;
	font-weight: 500;
	padding: 0.12rem 0.2rem;
	font-size: 0.14rem;
	border-radius: 0.04rem;
}



.el-textarea__inner {
	display: block;
	resize: vertical;
	padding: 0.05rem 0.15rem;
	line-height: 1.5;
	box-sizing: border-box;
	width: 100%;
	font-size: 0.14rem;
	color: #606266;
	background-color: #fff;
	background-image: none;
	border: 1px solid #dcdfe6;
	border-radius: 0.04rem;
	transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.el-form-item {
	margin-bottom: 0.22rem;
}

.demo {
	float: left;
	width: 0px;
	height: 0px;
	border: 0.05rem solid;
	/* margin-left: 0.85rem; */
	border-right-color: transparent;
	border-left-color: transparent;
	/* border-top-color: deepskyblue; */
	border-bottom-color: transparent;
}

.bg-zhichi {
	background-color: rgba(242, 242, 242);
}

.swiper-text {
	width: 0.5rem;
	height: 0.5rem;
	display: inline-block;
}

.bg-swiper {
	background-color: #f5f7f4;
}

.swiper-img {
	height: 2.5rem;
	object-fit: cover;
}

.p-btn {
	padding: 0.1rem 0;
}

.h-1-5 {
	height: 1.5rem;
}

.h-5 {
	height: 5rem;
}

.w-5 {
	width: 5rem;
}

.wh-1-5 {
	width: 100%;
	/* width: 1.5rem; */
	height: 1.5rem;
}
</style>
