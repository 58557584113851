<template>
    <div class="all">
        <div class="active">
            <div class="active-text">OPERATIONAL SUPPORT </div>
            <div class="active-text">运营支持</div>
            <div class="active-list">
                <div class="active-list-back" v-for="item in operationList" :key="item.id" @click="active=item.id"
                    :class="active == item.id?'msg':''">
                    <div>
                        <p>{{item.name}}</p>
                        <p>{{item.text}}</p>
                        <span :class="active == item.id?'triangle ':''"></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="top" v-if="active==0">
            <div class="handbook">
                <img src="../assets/img/zhidaoshouce.png" alt="">
                <div></div>
            </div>
            <div class="right">
                <div class="circle"></div>
                <div class="circle"></div>
                <div class="text">
                    <p v-for="item in textList" :key="item.content">{{item.content}}</p>
                </div>
                <div class="guidance">
                    <div>
                        指导手册
                    </div>
                    <div>INSTRUCTION MANUAL</div>
                    <div></div>
                </div>
            </div>
            <div class="introduce">
                <div>
                    盟校标准化运营，提供系统培训和运营指导，强化盟校招生力量，扩大市场，提高校区在当地知名度和影响力，提升校区经营业绩。
                </div>
                <div>

                </div>
            </div>
            <div class="frame"></div>
            <div class="bottom">MANUAL</div>
        </div>

        <div class="top-2" v-if="active==1">
            <div class="up">
                <div class="garden">
                    <div class="circle"></div>
                    <div class="circle"></div>
                </div>
                <div class="msg">
                    <div>
                        CLSS
                    </div>
                    <div></div>
                    <div>运营驻店指导</div>
                </div>
            </div>

            <div class="line"></div>

            <div class="subcontent   teacher">
                <div class="  d-none d-sm-block">
                    <div class="d-flex justify-content-center">
                        <div class="  container  mt-2 p-0">
                            <div class="d-flex flex-row" style="justifyContent:space-evenly">
                                <div class=" p-0 m-0" v-for="(v,i) in lbtList" :key="i" :class="i>1?'col-5':'col-3'">
                                    <div class="img-ii px-0-5">


                                        <el-carousel class=" w-100" indicator-position="none" height="300px"
                                            arrow="nerver" @change="i>1?docChange2($event,i):docChange($event,i)">
                                            <el-carousel-item class="w-100 h-100" v-for="(item,index) in  v"
                                                :key="item">
                                                <img class="w-100 h-100" style="object-fit: cover;" :src="$imgURL+item"
                                                    alt="" srcset="" />
                                            </el-carousel-item>
                                        </el-carousel>

                                        <div class="swiper-pagination" slot="pagination"></div>

                                        <div class="title font py-0-5" style="letter-spacing: 0.01rem;">{{v.title}}
                                        </div>

                                        <div class="progress ">
                                            <div class="sub-progress bg-yellow"
                                                :style="{width:i>1?'20%':'33.333333%',marginLeft:i>1? x2+'%':x1+'%'}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- </swiper> -->
                        </div>
                    </div>

                </div>

            </div>

            <div class="text">
                OPERATION INSTRUCTION
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            textList: [
                {
                    content: "长脸叔叔选址手册"
                },
                {
                    content: "长脸叔叔前台手册"
                },
                {
                    content: "长脸叔叔教师手册"
                },
                {
                    content: "长脸叔叔员工手册"
                },
                {
                    content: "长脸叔叔管理手册"
                },
                {
                    content: "长脸叔叔人事指导手册"
                },
                {
                    content: "长脸叔叔岗位制度手册"
                },
                {
                    content: "长脸叔叔市场运营手册"
                },
                {
                    content: "长脸叔叔百问百答手册"
                },
                {
                    content: "长脸叔叔教你打造朋友圈"
                },
            ],
            dongTaiDoc: 0,
            data: {},
            active: 0,
            x1: 0,
            x2: 0,
            operationList: [
                {
                    id: 0,
                    name: "指导手册",
                    text: "INSTRUCTION MANUAL"
                },
                {
                    id: 1,
                    name: "运营驻店指导",
                    text: "OPERATION INSTRUCTION"
                },
            ],
            lbtList: []
        }
    },
    mounted() {
        this.getData()
        this.getlbtList()
    },
    methods: {
        getNum(e) {
            let num = 0
            if (e == 0) {
                num = 0
            } else if (e == 1) {
                num = 33.333333
            } else if (e == 2) {
                num = 66.666666
            }
            return num
        },
        getNum2(e) {
            let num = 0
            if (e == 0) {
                num = 0
            } else if (e == 1) {
                num = 20
            } else if (e == 2) {
                num = 40
            } else if (e == 3) {
                num = 60
            } else if (e == 4) {
                num = 80
            }
            return num
        },
        docChange(index) {
            this.x1 = this.getNum(index)
        },
        docChange2(index) {
            this.x2 = this.getNum2(index)
        },
        getData() {
            this.$axios({
                method: "post",
                url: this.$apiURL + "api/index/index",
            }).then((res) => {
                if (res.code < 1) {
                    console.log("网络错误");
                    return false;
                }

                if (res.data.data) {
                    res.data.data.dynanics.map(item => {
                        let obj = item
                        item.doc = 0
                    })
                    this.data = res.data.data

                }
            });
        },
        // 获取运营驻店指导轮播图
        getlbtList() {
            this.$axios({
                method: "post",
                url: this.$apiURL + "/api/index/yunYinList",
            }).then((res) => {

                this.lbtList = Object.values(res.data.data)
                this.lbtList
                console.log("轮播图列表", this.lbtList);
            });
        },
        dongTaiChange(e) {
            let num = this.getNum(e)
            this.dongTaiDoc = num
        },
    },
}
</script>

<style lang="scss" scoped>
.all {
    margin-bottom: 1.55rem;
}

.active {
    padding-top: 1.64rem;
    width: 100%;
    height: 7.46rem;
    background-color: #f6f6f6;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-bottom: 1.2rem;

    &-text {
        text-align: center;
        width: 66%;
        margin: 0 auto;

        &:nth-child(1) {
            font-size: .66rem;
            font-family: Galvji;
            font-weight: bold;
            color: #000000;
        }

        &:nth-child(2) {
            font-size: .42rem;
            font-family: Heiti SC;
            font-weight: 300;
            color: #000000;
            margin: .68rem auto;
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        width: 12.75rem;
        margin: 0 auto;
        justify-content: center;

        .msg {
            background-color: #FDD23E;
            box-shadow: 0px 0px .2rem 0px rgba(0, 0, 0, 0.3);

            &::after {
                display: block;
            }

            &::before {
                display: block;
            }
        }

        span {
            display: none;
        }

        &-back {
            width: 6rem;
            height: 1.75rem;
            position: relative;
            background-color: #FFFFFF;
            border-radius: 0.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 200;
            cursor: pointer;

            &::before {
                display: none;
                position: absolute;
                bottom: -0.14rem;
                content: '';
                width: .3rem;
                height: .3rem;
                background: #FDD23E;
                transform: rotate(135deg);
                box-shadow: 0px 0px .2rem 0px rgba(0, 0, 0, 0.3);
            }

            &::after {
                display: none;
                position: absolute;
                bottom: 0px;
                content: '';
                width: 1rem;
                height: .4rem;
                background: #FDD23E;

            }


            div {
                width: 100%;
                height: 100%;
                display: flex;
                flex-flow: column;
                justify-content: center;
                border-radius: 0.2rem;

                p {
                    text-align: center;

                    &:nth-child(1) {
                        font-size: .3rem;
                        font-family: Heiti SC;
                        font-weight: 300;
                        color: #000000;
                    }

                    &:nth-child(2) {
                        font-size: .1rem;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #000000;
                    }
                }
            }
        }
    }
}


.top {
    text-align: center;
    width: 75%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    position: relative;

    .bottom {
        font-size: 2.11rem;
        color: #B7B7B7;
        opacity: 0.15;
        position: absolute;
        z-index: -2;
        left: -1.03rem;
        top: 9.2rem;
    }
}

.handbook {
    display: block;
    width: 7.86rem;
    height: 10.51rem;
    // border: 1px solid red;
    overflow: hidden;

    img {
        display: block;
        width: 100%;
        height: 100%;
    }

    div {
        width: 7.93rem;
        height: 10.51rem;
        border: .08rem solid #FFFFFF;
        position: relative;
        left: 1.33rem;
        top: -9.01rem;
    }
}

.frame {
    width: 7.93rem;
    height: 10.51rem;
    border: .06rem solid #FDD23E;
    position: absolute;
    left: 1.35rem;
    top: 1.5rem;
    z-index: -1;
}

.right {
    width: 40%;
    // border: 1px solid red;
    display: flex;
    flex-flow: column;
    align-items: end;
    position: relative;

    .guidance {
        width: 100%;
        text-align: left;
        position: relative;
        top: -1rem;

        div {
            &:nth-child(1) {
                font-size: .5rem;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #323232;
                line-height: .58rem;
            }

            &:nth-child(2) {
                font-size: .2rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #8D8D8D;
                line-height: .58rem;
            }

            &:nth-child(3) {
                width: 1rem;
                height: 0.06rem;
                background: #FDD23E;
            }
        }

    }


}

.introduce {
    position: absolute;
    top: 6.94rem;
    right: 0rem;
    width: 7.93rem;

    div {
        width: 100%;

        &:nth-child(1) {
            height: 3.23rem;
            background: #FDD23E;
            padding: 12% 10%;
            color: #FFFFFF;
            font-size: .24rem;
            text-indent: .5rem;
        }

        &:nth-child(2) {
            height: .97rem;
            background: #F1F1F1;
        }
    }
}

.circle {
    width: 0.44rem;
    height: 0.44rem;
    border: 0.06rem solid #FDD23E;
    border-radius: 50%;

    &:nth-child(1) {
        margin-top: 0.4rem;
    }

    &:nth-child(2) {
        margin-top: 0.08rem;
        opacity: 0.47;
    }
}

.text {
    margin-top: 0.13rem;
    color: #CBCBCB;
    font-size: 0.18rem;
    padding-top: 0.15rem;
    border-right: 0.04rem solid #E1E1E1;
    margin-right: 0.2rem;
    height: 4rem;

    p {
        text-align: right;
        margin-right: 0.2rem;
    }
}

.top-2 {
    width: 90%;
    margin: 0 auto;
    position: relative;
    margin-top: 2rem;

    .up {
        display: flex;
        justify-content: space-between;

        .msg {

            div {
                &:nth-child(1) {
                    font-size: 0.5rem;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #FDD23E;
                }

                &:nth-child(2) {
                    width: 159px;
                    height: 7px;
                    background: #FDD23E;
                }

                &:nth-child(3) {
                    font-size: 0.3rem;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #FDD23E;
                }
            }
        }
    }

    .line {
        position: absolute;
        width: 0.05rem;
        height: 4.85rem;
        border-radius: 0.02rem;
        background-color: #E1E1E1;
        left: .18rem;
        margin-top: .1rem;
    }

    .d-sm-block {
        width: 97%;
        float: right;
    }

    .progress {
        // width: 4.5rem;
        height: 0.07rem;
        margin-top: 1rem;
        background: #d8d8d8;

        .sub-progress {
            width: 0.4rem;
            transition: 0.4s all;

            height: 100%;
            background: #000000;
        }
    }

    .text {
        font-size: 1.18rem;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #B7B7B7;
        opacity: 0.15;
        text-align: center;
        height: 3rem;
    }
}
</style>